<template>
  <div class="lg:text-xl contact-us home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeaderBH />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner pt-12 text-gray-800 bg-center">
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">Hubung Kami</h2>
        <p class="lg:text-base text-sm">Beranda > <span class="text-sitePurple font-bold">Hubung Kami</span></p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl pt-12 pb-8 mx-auto">
        <p class="lg:mb-16 lg:px-0 px-4 mb-8">Dr Marco selalu mengutamakan pasien, dan telah mendedikasikan dirinya
          untuk memberikan perawatan menyeluruh untuk memastikan pengalaman pasien yang positif. Dengan pengalaman lebih
          dari 35 tahun di bidangnya, Dr Marco selalu siap berbagi pengetahuannya untuk membantu pasien membuat
          keputusan yang tepat demi pemulihan yang lancar.</p>
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <img class="mb-4 border-2 border-white" src="images/image-doctor.jpg">
          </div>
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <div class="lg:pl-8">
              <p class="lg:text-xl mb-2 text-lg font-bold">Dr Marco Faria Correa</p>
              <p class="mb-8">
                Dip Dokter. (Rio Grande do Sul), Sertifikat Pascasarjana. (Bedah Plastik, Brasil),
                Sertifikat Spesialis. (Bedah Plastik, Brasil), TMSBCP (Bedah Plastik, Brasil),
                Spesialis Bedah Plastik Terakreditasi (Departemen Kesehatan, Singapura)
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SiteFormBH />
    <SiteFooterBH />
  </div>
</template>

<script>
  import SiteHeaderBH from '@/components/SiteHeaderBH.vue'
  import SiteFormBH from '@/components/SiteFormBH.vue'
  import SiteFooterBH from '@/components/SiteFooterBH.vue'
  export default {
    components: {
      SiteHeaderBH,
      SiteFormBH,
      SiteFooterBH
    },
    mounted() {  
      document.title = "Dr Marco Faria Correa Plastic Surgery - Operasi Plastik";  
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
</style>